body, html {
  margin: 0;
}

canvas {
  display: block;
  cursor: move;
}

.hide {
  display: none;
}