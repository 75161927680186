@import url(https://fonts.googleapis.com/css?family=Share+Tech+Mono);

@property --rotate {
  syntax: "<angle>";
  initial-value: 132deg;
  inherits: false;
}

:root {
  --card-height: 40vh;
  --card-width: calc(var(--card-height) / 1.5);
}


body {
  min-height: 100vh;
  background : url(https://image.ibb.co/h2hLAJ/bg.png) #000000;
  font-family: 'Share Tech Mono', monospace;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 2rem;
  padding-bottom: 2rem;
  box-sizing: border-box;
}

/* pixelate the border  vvvvv */

.card {
  background: url(https://image.ibb.co/h2hLAJ/bg.png) #000000;
  width: var(--card-width);
  height: var(--card-height);
  padding: 10px;
  position: relative;
  border-radius: 1px;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  font-size: 1.5em;
  color: #FF0000;
  font-family: 'Share Tech Mono', monospace;
}

.card:hover {
  color: #ffffff;
  transition: color 1s;
}
.card:hover:before, .card:hover:after {
  animation: none;
  opacity: 0.2;
}


.card::before {
  content: "";
  width: 104%;
  height: 102%;
  border-radius: 3px;
  background-image: linear-gradient(
    var(--rotate),
    #000000, #FF0000 43%, #000000);
    position: absolute;
    z-index: -1;
    top: -1%;
    left: -2%;
    animation: spin 2.5s linear infinite;
}

.card::after {
  position: absolute;
  content: "";
  top: calc(var(--card-height) / 6);
  left: 0;
  right: 0;
  z-index: -1;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  transform: scale(0.8);
  filter: blur(calc(var(--card-height) / 6));
  background-image: linear-gradient(
    var(--rotate)
    , #000000, #FF0000 43%, #000000);
    opacity: 1;
  transition: opacity .5s;
  animation: spin 2.5s linear infinite;
}

.hack {
  border: none;
  margin: 20px;
  padding: 10px 40px;
  width: auto;
  overflow: visible;
  outline: 0;
  cursor: pointer;
  background: 0 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  background: rgba(219, 14, 21, .2);
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: bold;
  width: 60%;
}

a {
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1rem;
}

@keyframes spin {
  0% {
    --rotate: 0deg;
  }
  100% {
    --rotate: 360deg;
  }
}