@keyframes glitch1 {
	2%,
	64% {
		transform: translate(1px, 0) skew(0deg);
	}
	4%,
	60% {
		transform: translate(-5px, 0) skew(4deg);
	}
	62% {
		transform: translate(8px, 0) skew(6deg);
	}
}

@keyframes glitch2 {
	2%,
	64% {
		transform: translate(1px, 0) skew(0deg);
	}
	4%,
	60% {
		transform: translate(5px, 0) skew(2deg);
	}
	62% {
		transform: translate(-8px, 0) skew(7deg);
	}
}

.daemonStorage {
  display: flex;
  flex-direction: column;
  padding: 1%;
}

.daemonButton {
  border: none;
  margin: 20px;
  padding: 10px 40px;
  width: auto;
  height: auto;
  overflow: visible;
  outline: 0;
  cursor: pointer;
  background: 0 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  background: rgba(219, 14, 21, .2);
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  font-size: 2vw;
}

.daemonLink {
  border: none;
  margin: 5px;
  padding: 10px 20px;
  width: 40%;
  max-width: 400px;
  height:20%;
  overflow: visible;
  outline: 0;
  cursor: pointer;
  background: 0 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  background: rgba(219, 14, 21, .2);
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.2vw;
}

#landing:hover, #about:hover, #projects:hover, #resume:hover, #contact:hover {
  animation-play-state: paused;
  animation: glitch .8s linear infinite;
  color: #ffffff;
  background: url(https://image.ibb.co/h2hLAJ/bg.png) #000;
  transition: opacity .5s;
  text-shadow: 0 0 5px rgba(219, 14, 21, .8);
}

.daemonLink:hover {
  animation-play-state: paused;
}

.loading {
  border: solid 3px #ff4d4d;
  animation: boxShadow 1.6s infinite;
  padding: 1em;
  width: 100px;
  height: 100px;
}

/* add an @media for mobile design to make buttons fit and text in buttons resize using font-size: 4vw; */

@media only screen and (max-width:600px) {
  .daemonLink, .daemonButton {
    font-size: 1rem;
  }
}