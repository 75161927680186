@import url(https://fonts.googleapis.com/css?family=Share+Tech+Mono);

/* Blink on and off */
@keyframes blink {
	0.01% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
	50.01% {
		opacity: 1;
	}
}


/* Scanline height 1005 */
@keyframes scanline {
	0.01% {
    height: 0;
  }
  99.99% {
    height: 100%
  }
	100% {
		height: 0;
	}
}

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

body {
  color: #db0e15;
  font-family: 'Share Tech Mono', monospace;
  font-size: 1.4rem;
  font-weight: 300;
  text-shadow: 0 0 5px rgba(219, 14, 21, .8);
  background: url(https://image.ibb.co/h2hLAJ/bg.png) #000;
  background-repeat: repeat-y;
  background-repeat: repeat-x;
  overflow: auto;
  position: relative;
}

p,
pre {
	font-family: 'Share Tech Mono', monospace;
	font-size: inherit;
	line-height: 1;
	margin: 0;
	display: block;
	padding: 5px;
	max-width: 100%;
  font-size: 2vw;
}

.blink {
	opacity: 1;
	animation: blink 1s linear infinite;
	max-width: 100%;
  font-size: 2vw;
}

.scanline {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(255,255,255,0.03);
  background-repeat: repeat-y;
	animation: scanline 8s linear infinite;
	pointer-events: none;
	max-width: 100%;
  font-size: 1vw;
}

.hack {
  border: none;
  margin: 20px;
  padding: 10px 40px;
  width: auto;
  overflow: visible;
  outline: 0;
  cursor: pointer;
  background: 0 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  background: rgba(219, 14, 21, .2);
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: bold;
}

#deploydemos {
  max-width: 90%;
}

#input1 {
  border: 2px solid #d7001e;
  margin: 10px 0;
  padding: 10px;
  width: auto;
  max-width: 100%;
  overflow: visible;
  outline: 0;
  background: 0 0;
  color: inherit;
  font: inherit;
  line-height: normal
}

#input1::-moz-selection {
  background: #000
}

#input1::selection {
  background: #000
}

a:hover {
  color: red;
}

#contactlinks {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

#winterMute {
  position: absolute;
  justify-content: center;
  border: 2px solid red;
  max-width: 90%;

}

@media only screen and (max-width:1260px) {
  h5::after,
  h5::before {
    width: 1rem;
    height: 18px
  }
  .col.col__center,
  .col.col__left {
    display: block;
    width: auto;
    margin: 0
  }
  .box--outer::after,
  .box--outer::before {
    display: none
  }
  h5::after,
  h5::before {
    margin: 0 5px
  }
  .content {
    height: 440px
  }
  .box::after,
  .box::before {
    height: 500px
  }
  #submit {
    margin-bottom: 0
  }
  .hack {
    font-size: 1rem;
  }
}

@media only screen and (max-width:600px) {
  .container {
    font-size: .8em
  }
  h5::after,
  h5::before {
    width: 40%;
    height: 18px
  }
  .box--outer {
    -o-border-image: url(https://image.ibb.co/kHHeny/hor_line.png) 17 330 17 round;
    border-image: url(https://image.ibb.co/kHHeny/hor_line.png) 17 330 17 round
  }
  .box::after,
  .box::before {
    width: 22px;
    background: url(https://image.ibb.co/kHHeny/ver_line_mobile.png) no-repeat center;
    background-size: contain
  }
  .box--inner {
    width: calc(100% - 44px)
  }
  #input1 {
    max-width: 70%
  }
  .screen::after {
    max-width: 180px
  }
  .hack, NavLink {
    font-size: .5rem;
    overflow: hidden;
  }
}